import React from "react";
import "./styles.css";
import Layout from "../../../components/layout";
import Header from "../../../components/header";
import HeroBanner from "../../../components/HeroBanner";
import WithProviders from "../../../components/WithProviders";

import ProductWithImage from "../../../components/ProductWithImage";

import MicroserviceSegmetImg from "../../images/microservice-segment-img.png";
import MicroserviceSegmetImg2 from "../../images/microservice-segment-img@2x.png";
import MicroserviceSegmetImg3 from "../../images/microservice-segment-img@3x.png";

import SecureLockIcon from "../../images/secure-lock.svg";
import InventoryIcon from "../../images/inventry-icon.svg";
import CatalogueIcon from "../../images/catelogue-icon.svg";
import PriceTagIcon from "../../images/price-tag.svg";
import MobileDeviceIcon from "../../images/devices-icon.svg";
import NotificationIcon from "../../images/notification-icon.svg";
import WebsiteIcon from "../../images/website-icon.svg";
import LogisticsIcon from "../../images/logistics-icon.svg";
import TriangleIcon from "../../images/right-triangle.svg";
import { Message } from "../../../lib/getMessage";

const systemTypes = [
  {
    key: "Security",
    icon: SecureLockIcon,
  },
  {
    key: "Inventory",
    icon: InventoryIcon,
  },
  {
    key: "Catalogue",
    icon: CatalogueIcon,
  },
  {
    key: "Pricing",
    icon: PriceTagIcon,
  },
  {
    key: "Mobile",
    icon: MobileDeviceIcon,
  },
  {
    key: "Notification & Comms",
    icon: NotificationIcon,
  },
  {
    key: "Website",
    icon: WebsiteIcon,
  },
  {
    key: "Logistics",
    icon: LogisticsIcon,
  },
];

function MicroserviceApplicationEnvironment(props) {
  const { language, updateLanguage, direction, location } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={location}
    >
      <Header
        current="/service"
        language={language}
        updateLanguage={updateLanguage}
        direction={direction}
      />
      <div className="sr-microservice-page">
        <HeroBanner
          title={
            <Message dictKey="services.microservicesapplicationenvironment.title" />
          }
          className="microservice-banner"
        />
        <section className="service-product-img">
          <ProductWithImage
            name="service-segment"
            className="service-seg"
            description={[
              {
                type: "p",
                val: (
                  <Message dictKey="services.microservicesapplicationenvironment.servicesegment.description1" />
                ),
                id: 1,
              },
              {
                type: "p",
                val: (
                  <Message dictKey="services.microservicesapplicationenvironment.servicesegment.description2" />
                ),
                id: 2,
              },
              {
                type: "p",
                val: (
                  <Message dictKey="services.microservicesapplicationenvironment.servicesegment.description3" />
                ),
                id: 3,
              },
            ]}
            image={{
              normal: MicroserviceSegmetImg,
              xx: MicroserviceSegmetImg2,
              xxx: MicroserviceSegmetImg3,
            }}
            isDirectionRTL={isDirectionRTL}
          />
        </section>
        <section className="sr-micro-systems">
          <div className="monolythic-service">
            <h1 className="sys-heading">
              <Message dictKey="services.microservicesapplicationenvironment.monolithic" />
            </h1>
            <div className="mono-sys-wrap">
              {systemTypes.map((service) => {
                return (
                  <div
                    className={`${service.key.split(" ")[0]}-mono mono-item`}
                  >
                    <img
                      className={`${service.key.split(" ")[0]}-icon monoIcon`}
                      src={service.icon}
                      alt="icon"
                      loading="lazy"
                    />
                    <p className="key-text">{service.key}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="triangle-wrp">
            <img
              className="triangle-icon"
              src={TriangleIcon}
              alt="triangle-svg"
              loading="lazy"
            />
          </div>
          <div className="micro-service">
            <h1 className="sys-heading">
              <Message dictKey="services.microservicesapplicationenvironment.microservices" />
            </h1>
            <div className="micro-sys-wrap">
              {systemTypes.map((service) => {
                return (
                  <div
                    className={`${service.key.split(" ")[0]}-micro micro-item`}
                  >
                    <img
                      className={`${service.key.split(" ")[0]}-icon microIcon`}
                      src={service.icon}
                      alt="icon"
                      loading="lazy"
                    />
                    <p className="key-text">{service.key}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default WithProviders(MicroserviceApplicationEnvironment);
